import * as React from "react"
import { Layout } from "../components/Layout";
import "../components/index.css";
import { Home } from "../components/Home/Home";

const LandingScreen = () => {
  return (
    <div>
      <Layout >
        <Home />
        </Layout>
    </div>
  )
}

export default LandingScreen;