import homeFirstImage from "../images/homeFirstImage.svg";
import resize from "../images/resize.svg";
import objectiveLogo from "../images/objectiveLogo.svg";
import teamsLogo from "../images/teamsLogo.svg";
import hrmsImage from "../images/hrmsImage.svg";
import onboardIcon from "../images/onboardIcon.svg";
import walletIcon from "../images/walletIcon.svg";
import verifyIcon from "../images/verifyIcon.svg";
import attendanceIcon from "../images/attendanceIcon.svg";

import pillarCompanies from "../images/pillarCompanies.svg";
import listsIcon from "../images/listsIcon.svg";
import devicesIcon from "../images/devicesIcon.svg";
import zeroRiskIcon from "../images/zeroRiskIcon.svg";


import resFirstImage from "../images/resFirstImage.svg";
import resSecondImage from "../images/resSecondImage.svg";
import resThirdImage from "../images/resThirdImage.svg";
import line from "../images/line.svg";



export const homeContent = {
    sectionOne: {
        titleOne: "Integrated and connected",
        titleTwo: "workforce management ecosystem",
        objectiveLine: "One solution to manage all your frontline workforce needs",
        bookButton: "Book a demo",
        bannerImage: homeFirstImage
    },
    sectionTwo: {
        title: { normal: "Digital solutions that ", coloured: "adapt to business efficiency" },
        paraLine: "Empower your organization with tools that are easy to use and capabilities that fit your workforce's needs.",
        columnData: {
            firstColumn: {
                icon: resize,
                text: "Spectrawise is a full-suite workforce management platform that is simple, easy to use, and easy to plug in with AI-driven efficiency."
            },
            secondColumn: {
                icon: objectiveLogo,
                text: "The product is designed for all levels of an organization to offer an engaging experience to all stakeholders (business leaders, managers and workers)."
            },
            thirdColumn: {
                icon: teamsLogo,
                text: "Equip yourself with a product that efficiently manages all your people management functions within a single database to broaden your management capabilities."
            }
        }
    }
};



export const homeHRMSContent = {
    title: { normal: "Spectrawise:", coloured: "The Best HRMS Online" },
        data: [
            {
                img: walletIcon,
                name: "Payroll",
                subtext: "Enables anytime, anywhere access to accurately process payroll"
            },
            {
                img: verifyIcon,
                name: "Verify",
                subtext: "Screen your workforce's background in no time."
            },
            {
                img: attendanceIcon,
                name: "Attendance",
                subtext: "Automate attendance. Time tracking made easy, convenient and data driven"
            },
            {
                img: onboardIcon,
                name: "Onboard",
                subtext: "Makes complex manual onboarding process simpler and hassle free for new hire"
            }
        ],
        hrmsImage: hrmsImage
}

export const homePillarsContent = {
    boxOne: {
        title: {normal:"Core ",coloured:"strength of our suite"},
        text: "Advanced analytics tools to evaluate trends and offer insight to assist informed decision-making.",
        pillarCompaniesLogo:pillarCompanies,
    },
    boxTwo: {
        title: {normal:"What ",coloured:"sets up ahead?"},
        text:"Spectrawise is a support system for organizations looking forward to digitizing their workforce management to enhance business success."
    },
    boxThree: [
        {
            text: "Enabling workforce planning, management, and tracking",
            icon:devicesIcon
        },
        {
            text: "Best AI solution to optimize workforce efforts",
            icon:listsIcon
            
        },
        {
            text: "Cost effective solution with no-risk",
            icon:zeroRiskIcon
            
        }
    ]
}


export const homeResourcesContent = {
    title: {
        normal: "Facilitating workforce management ",
        coloured:"eliminating repetitive process"
    },
    text: "Consolidate the HR operation in more people-centric manner ",
    data: [
        {
            img: resFirstImage,
            name: "Simplify people management",
            subtext:"Track your workforce performance, development and engagement at a central hub without compromising on the confidentiality"
        },
        {
            img: resSecondImage,
            name: "Streamline Recruitment pipeline",
            subtext:"Automate and streamline candidates through your pipeline for your business including new hire onboarding and employee acknowledgment."
        },
        {
            img: resThirdImage,
            name: "Mobility benefits for administration ",
            subtext:"Utilize electronic carrier connections anytime and anywhere access"
        }
    ],
    lineImage:line
}